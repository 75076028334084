<template>
  <!-- 多规格值 页面 -->
  <div class="container">
    <a-row>
      <a-col :span="4">
        <vue-scroll :ops="ops" style="width: 100%; height: 60vh; margin-top: 12px">
          <a-tree
            class="title"
            :selected-keys="selectedKeys"
            :tree-data="treeData"
            :auto-expand-parent="autoExpandParent"
            default-expand-all
            @expand="onExpand"
            @select="onSelect"
            :defaultExpandParent="true"
            :expanded-keys="expandedKeys"
          />
        </vue-scroll>
      </a-col>
      <a-col :span="20" class="right">
        <div class="right-content">
          <a-spin tip="Loading..." :spinning="spinning">
            <div class="table-page-search-wrapper">
              <a-form layout="inline">
                <a-row :gutter="48">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="规格值名">
                      <a-input v-model="queryParam.name" allow-clear placeholder="请输入" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="状态">
                      <a-select v-model="queryParam.delFlag" allow-clear placeholder="请选择">
                        <a-select-option v-for="(item, index) in statusList" :key="index" :value="item.id">{{
                          item.name
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-button type="primary" @click="initTableData">查询</a-button>
                    <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                  </a-col>
                </a-row>
              </a-form>
            </div>
            <div class="table-operator" v-if="selectedKeys.length > 0">
              <a-button type="primary" icon="plus" @click="$refs.addForm.add(selectedKeys[0])">新增规格值</a-button>
            </div>
            <a-table
              :columns="columns"
              :data-source="datas"
              :pagination="false"
              :scroll="{ x: 1000 }"
              :loading="loading"
              rowKey="id"
              ><span slot="action" slot-scope="text, record">
                <a @click="$refs.editForm.edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => deleteSpecValue(record)">
                  <a>删除</a>
                </a-popconfirm>
              </span>
              <span slot="status" slot-scope="text, record" @click="clickSwitch(record)">
                <a-switch
                  checked-children="启用"
                  un-checked-children="禁用"
                  v-model="record.checked"
                  @change="changeSwitch"
                />
              </span>
            </a-table>
            <!-- 分页组件 -->
            <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
              <a-pagination
                :pageSize="page.pageSize"
                :current="page.pageNo"
                show-size-changer
                :page-size-options="['10', '20', '50', '100']"
                show-quick-jumper
                :total="total"
                :show-total="(total) => `共 ${total} 条`"
                @change="pageChange"
                @showSizeChange="sizeChange"
              ></a-pagination>
            </div>
          </a-spin>
        </div>
      </a-col>
    </a-row>
    <add-form ref="addForm" @ok="initTableData" />
    <edit-form ref="editForm" @ok="queryTableData" />
  </div>
</template>

<script>
import { specValuePageList, specValueList, specValueDelete } from '@/api/modular/mallLiving/specValue'
import { specNameList } from '@/api/modular/mallLiving/specName'

import addForm from './addForm'
import editForm from './editForm'
export default {
  components: {
    addForm,
    editForm,
  },
  data() {
    const vm = this

    return {
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      currentRecord: {},
      statusList: [
        { id: 1, name: '启用' },
        { id: 3, name: '禁用' },
      ],
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: '#333', //滚动条颜色
          opacity: 0.5, //滚动条透明度
          'overflow-x': 'hidden',
        },
      },
      queryParam: {},
      datas: [],
      title: '',

      columns: [
        {
          title: '序号',
          align: 'center',
          width: '80px',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '规格值名',
          dataIndex: 'name',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center',
        },
        {
          title: '状态',
          dataIndex: 'delFlag',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],

      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      treeData: [],
      type: 1,
      // 弹出层
      visible: false,
      standardrequest: false,
      detailShow: false,
      Treetype: 'top', // top center bottom
      // 加载状态
      spinning: false,
      expandedKeys: [],
      // 多级联动
      checkId: '',
      loading: false,
      currentItem: {},
      currentOrgName: '',
    }
  },
  props: [],
  computed: {},
  filters: {},
  mounted() {
    this.queryAllSpecList()
  },
  methods: {
    async queryAllSpecList() {
      let data = await specNameList({})
      if (data.code == 200) {
        this.expandedKeys = []
        this.selectedKeys = []
        var list = [{ key: -11, title: '规格', type: 'top', children: [] }]
        var dataList = data.data
        this.expandedKeys.push(-11)
        if (dataList.length > 0) {
          this.selectedKeys.push(dataList[0].id)
          this.initTableData()
        }
        dataList.forEach((element) => {
          element.type = 'bottom'
          element.title = element.name
          element.key = element.id
        })
        list[0].children = dataList
        this.treeData = list
      }
    },

    initTableData() {
      this.page.pageNo = 1
      this.queryTableData()
    },

    async queryTableData() {
      this.loading = true
      let params = {
        page: this.page,
        queryParam: { ...this.queryParam, specId: this.selectedKeys[0] },
      }
      let data = await specValuePageList(params)
      if (data.code == 200) {
        this.datas = data.data.rows
        this.datas.forEach((item) => {
          item.checked = item.delFlag == 1
        })
        this.datas = JSON.parse(JSON.stringify(this.datas))
        this.total = data.data.totalRows
      }
      this.loading = false
    },

    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.queryTableData()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },

    clickSwitch(record) {
      this.currentRecord = record
    },

    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableSpec(delFlag)
      }, 300)
    },

    async disableSpec(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.currentRecord.id,
      }
      let data = await specValueDelete(params)
      var text = delFlag == 1 ? '启用' : delFlag == 2 ? '删除' : '禁用'
      if (data.code == 200) {
        this.$message.success(this.currentRecord.name + ' 已' + text)
        this.queryTableData()
      }
    },

    deleteSpecValue(record) {
      this.currentRecord = record
      this.disableSpec(2)
    },
    onExpand(expandedKeys) {
      console.log('onExpand', expandedKeys)
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect(selectedKeys, info) {
      const types = info.selectedNodes.length > 0 ? info.selectedNodes[0].data.props.type : ''
      if (types && types == 'bottom') {
        this.selectedKeys = selectedKeys
        this.initTableData()
      }
    },
  },
}
</script>

<style lang="less" scoped>
// @import url(); 引入公共css
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
</style>
